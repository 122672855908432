/* Colors */
$primary: #0273E6;
$secondary: #01254C;
$text-color: #000000;
$background: #E6E9F2;
$text-gray: #5C6F82;

/* Spacers */

$spacers: (
    0: 0px,
    0.5: 0.125rem,
    1: 0.25rem,
    1.5: 0.375rem,
    2: 0.5rem,
    2.5: 0.625rem,
    3: 0.75rem,
    3.5: 0.875rem,
    4: 1rem,
    5: 1.25rem,
    6: 1.5rem,
    7: 1.75rem,
    8: 2rem,
    9: 2.25rem,
    10: 2.5rem,
    11: 2.75rem,
    12: 3rem,
    14: 3.5rem,
    16: 4rem,
    20: 5rem,
    24: 6rem,
    28: 7rem,
    32: 8rem,
    36: 9rem,
    40: 10rem,
    44: 11rem,
    48: 12rem,
    52: 13rem,
    56: 14rem,
    60: 15rem,
    64: 16rem,
    72: 18rem,
    80: 20rem,
    96: 24rem,
    auto: auto
);

/* Base */

html, body, #app {
    height: 100%;
}

html, body, #app {
    background-color: $background;
}

/* Input */

input {
    color: $secondary;
}

/* Chrome/Opera/Safari */
::-webkit-input-placeholder {
    font-style: italic;
}

/* Firefox 19+ */
::-moz-placeholder {
    font-style: italic;
}

/* IE 10+ */
:-ms-input-placeholder {
    font-style: italic;
}

/* Firefox 18- */
:-moz-placeholder {
    font-style: italic;
}

textarea {
    resize: none !important;
}

/* Text */

.text-blue {
    color: $primary;
}

.text-dark-blue {
    color: $secondary;
}

.text-gray {
    color: $text-gray;
}

/* Font sizes */

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-base {
    font-size: 1rem;
    line-height: 1.25rem;
}

/* Letter spacing */

.tracking {
    letter-spacing: 0.5px;
}

/* Links */

.no-underline {
    text-decoration: none;
}

.underline {
    text-decoration: underline;
}

.cursor-pointer{
    cursor: pointer;
}

.dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }
.text-red { color: #ff0000}

$link-hover-decoration: none;

/* Select */
.select {
    border: 0;
    border-bottom: 1px solid #000000;
    padding: 1em;
    background-color: #ffffff;
}

/* Request approval */
.request-approval {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  box-shadow: inset 0 0 0 1px #0273e6 !important;
}

@import "../../node_modules/bootstrap-italia/src/scss/bootstrap-italia.scss";
